import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CopyOutlined } from '@ant-design/icons'
import { Button, Drawer, Form, Input, Layout, Segmented, Select, Space, Switch } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import { SegmentedValue } from 'antd/es/segmented'
import { format, parse } from 'date-fns'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import styles from './ViewStudio.module.scss'
import { ICreateStudioDto } from '..'
import { AppConstants } from '../../../../AppContants'
import Loading from '../../../../components/Loading/Loading'
import Table, { IColumn, TableRow } from '../../../../components/Table'
import addSvg from '../../../../images/add.svg'
import copyToClipBoardSvg from '../../../../images/copyToClipboard.svg'
import Close from '../../../../images/cross.svg'
import deleteSvg from '../../../../images/delete.svg'
import Arrow from '../../../../images/dropArrow.svg'
import editScheduleSvg from '../../../../images/editSchedule.svg'
import { getCitiesAsync } from '../../../../redux/admin/platform'
import {
  getBedsAsync,
  getSlotsAsync,
  getStudioByIdAsync,
  postBedAsync,
  postSlotAsync,
  // postSlotAsync,
  putBedAsync,
  putStudioAsync,
  resetPostBedStatus,
  resetPostSlotStatus,
  // resetPostSlotStatus,
  resetPutBedStatus,
  // resetPutSlotStatus,
  resetPutStudioStatus,
  resetSlots,
  resetSlotsStatus,
} from '../../../../redux/admin/studio'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { StatusEnum } from '../../../../redux/type'
import { formatDayName, getUserTimezone } from '../../../../utils/DateAndTime'
import { NotificaitonPlacement, notifyError, notifySuccess } from '../../../../utils/Notification'

const ViewStudio = () => {
  const dispatch = useAppDispatch()
  const { id }: any = useParams()
  const studios = useAppSelector((state) => state.admin.studio.studios)
  const studioStatus = useAppSelector((state) => state.admin.studio.putStudioStatus)
  const apiError = useAppSelector((state) => state.admin.studio.error)
  const cities = useAppSelector((state) => state.admin.platform.cities)
  const cityOptions = cities?.map((s) => ({ label: s?.name, value: s?.name })) || {}
  const [form] = Form.useForm()
  const [activeTab, setActiveTab] = useState<SegmentedValue>('Table')
  const [showButtons, setShowButtons] = useState(false)

  const studio: any = useMemo(() => {
    let temp = studios?.find((s: any) => s.id === id)
    if (!temp) {
      return null
    } else {
      return {
        name: temp?.name || '',
        address: temp?.address || '',
        state: temp?.state || '',
        cityName: temp?.city?.name || '',
        zipCode: temp?.zipCode || '',
      }
    }
  }, [studios])

  useEffect(() => {
    dispatch(getCitiesAsync({}))
  }, [])

  useEffect(() => {
    const foundStudio = studios?.find((s: any) => s.id === id)
    if (!foundStudio) {
      dispatch(getStudioByIdAsync({ id: id }))
    }
  }, [studios])

  useEffect(() => {
    if (studioStatus === StatusEnum.Failed) {
      notifyError('Unable to update studio', apiError)
    } else if (studioStatus === StatusEnum.Success) {
      notifySuccess('Success', 'Studio updated successfully')
      setShowButtons(false)
    }
    return () => {
      dispatch(resetPutStudioStatus())
    }
  }, [studioStatus])

  const onFinish = (values: ICreateStudioDto) => {
    dispatch(putStudioAsync({ data: values, id: id }))
  }

  const shouldShowSaveButton = useCallback(
    (newValues: any) => {
      if (
        studio.name === newValues.name &&
        studio.address === newValues.address &&
        studio.state === newValues.state &&
        studio.city?.name === newValues.cityName &&
        studio.zipCode === newValues.zipCode
      ) {
        setShowButtons(false)
      } else {
        setShowButtons(true)
      }
    },
    [studio, setShowButtons],
  )

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const onCancel = () => {
    form.resetFields()
    setShowButtons(false)
  }

  const handleTabChange = (value: SegmentedValue) => {
    setActiveTab(value)
    // navigate(`/admin/platform/${value.toLocaleString().toLowerCase()}`)
  }
  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content style={{ padding: '2rem' }} className={styles.content}>
        <div className={styles.titleText}>Edit Studio</div>
        <div className={styles.editContainer}>
          {studio && (
            <Form
              labelWrap={true}
              form={form}
              name="basic"
              layout="vertical"
              // wrapperCol={{ span: 8 }}
              style={{}}
              initialValues={studio}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              requiredMark={false}
              autoComplete="off"
              onValuesChange={(c: any, a: any) => {
                shouldShowSaveButton(a)
              }}
            >
              <div className={styles.rowOne}>
                <Form.Item<ICreateStudioDto>
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input name!' }]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder="Enter Studio Name" style={{ textTransform: 'capitalize' }} />
                </Form.Item>

                <Form.Item<ICreateStudioDto>
                  label="Address"
                  name="address"
                  rules={[{ required: true, message: 'Please input address!' }]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder="Enter The Address" style={{ textTransform: 'capitalize' }} />
                </Form.Item>
              </div>
              <div className={styles.rowTwo}>
                <Form.Item<ICreateStudioDto>
                  label="City"
                  name="cityName"
                  rules={[{ required: true, message: 'Please input studio!' }]}
                  style={{ width: '100%' }}
                >
                  <Select
                    notFoundContent="No city available"
                    placeholder={'City'}
                    options={cityOptions}
                    suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
                    style={{ textTransform: 'capitalize' }}
                    dropdownStyle={{ textTransform: 'capitalize' }}
                  ></Select>
                </Form.Item>
                <Form.Item<ICreateStudioDto>
                  label="State"
                  name="state"
                  rules={[{ required: true, message: 'Please input state!' }]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder="State" style={{ textTransform: 'capitalize' }} />
                </Form.Item>
              </div>
              <div className={styles.rowThree}>
                <Form.Item<ICreateStudioDto>
                  label="Zip Code"
                  name="zipCode"
                  rules={[{ required: true, message: 'Please input email!' }]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder="Zip Code" />
                </Form.Item>
                <div className={styles.buttonContainer}>
                  {showButtons && (
                    <Form.Item>
                      <div className={styles.footer}>
                        <Button className={styles.button} children="Cancel" type="default" onClick={onCancel}></Button>
                        <Button className={styles.button} children="Save" htmlType="submit" type="primary"></Button>
                      </div>
                    </Form.Item>
                  )}
                </div>
              </div>
            </Form>
          )}
        </div>
        <div className={styles.bedSlotContainer}>
          <Segmented
            className={styles.toggle}
            options={['Table', 'Schedule']}
            value={activeTab}
            onChange={handleTabChange}
          ></Segmented>
          <div>
            {activeTab === 'Table' && <BedTab></BedTab>}
            {activeTab === 'Schedule' && <SlotTab></SlotTab>}
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default ViewStudio

export const BedTab = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const bedsMetaData: any = useAppSelector((state) => state.admin.studio.bedsMetaData)
  const bedsStatus = useAppSelector((state) => state.admin.studio.bedsStatus)
  const apiError = useAppSelector((state) => state.admin.studio.error)
  const beds = useAppSelector((state) => state.admin.studio.beds)
  const [open, setOpen] = useState(false)
  const [showEditBed, setShowEditBed] = useState(false)
  const [bedId, setBedId] = useState('')

  const columns: Array<IColumn> = [
    {
      capitalize: true,
      displayName: 'Name',
      key: 'bedName',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Code',
      key: 'bedCode',
    },
  ]

  const rows: Array<TableRow> = useMemo(() => {
    return beds.map((b) => {
      return {
        id: b.id || '',
        bedName: b.name || '',
        bedCode: b.code || '',
      }
    })
  }, [beds])

  useEffect(() => {
    dispatch(getBedsAsync({ studioId: id }))
  }, [])

  useEffect(() => {
    if (bedsStatus === StatusEnum.Failed) {
      notifyError('Unable to fetch beds', apiError)
    } else if (bedsStatus === StatusEnum.Success) {
    }
  }, [bedsStatus])

  return (
    <div>
      <div style={{ height: bedsMetaData?.totalRecordCount > 20 ? '35vh' : '41vh' }}>
        {bedsStatus === StatusEnum.Pending && <Loading style={{ background: 'none' }}></Loading>}

        {bedsStatus === StatusEnum.Success && (
          <Table
            columns={columns}
            rows={rows}
            buttonText="New Table"
            totalPageCount={bedsMetaData?.totalRecordCount}
            currentPage={bedsMetaData?.currentPage}
            onRowClick={(id) => {
              setBedId(id)
              setShowEditBed(true)
            }}
            onButtonClick={() => {
              setOpen(true)
            }}
            onPageChange={(page, pageSize) => {
              dispatch(
                getBedsAsync({
                  studioId: id,
                }),
              )
            }}
            initalButtonChildren={'Create Table'}
            onInitialButtonClicked={() => {
              setOpen(true)
            }}
          ></Table>
        )}
      </div>
      <Drawer
        title="New Table"
        closable={false}
        extra={
          <Space>
            <div
              onClick={() => {
                setOpen(false)
              }}
              className={styles.closeContainer}
            ></div>
          </Space>
        }
        open={open}
      >
        {open && (
          <AddBed
            onClose={() => {
              setOpen(false)
            }}
            onCreate={() => {
              setOpen(false)
            }}
          ></AddBed>
        )}
      </Drawer>
      <Drawer
        title="Edit Table"
        closable={false}
        extra={
          <Space>
            <div
              onClick={() => {
                setShowEditBed(false)
              }}
              className={styles.closeContainer}
            ></div>
          </Space>
        }
        open={showEditBed}
      >
        {showEditBed && (
          <EditBed
            bedId={bedId}
            onClose={() => {
              setShowEditBed(false)
            }}
            onUpdate={() => {
              setShowEditBed(false)
            }}
          ></EditBed>
        )}
      </Drawer>
    </div>
  )
}

interface AddBedProps {
  onClose: () => void
  onCreate?: () => void
}
export interface ICreateBed {
  name: string
  clinicId: string | undefined
}
export const AddBed = ({ onClose, onCreate }: AddBedProps) => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const postBedStatus = useAppSelector((state) => state.admin.studio.postBedStatus)
  const apiError = useAppSelector((state) => state.admin.studio.error)
  const [form] = Form.useForm()

  useEffect(() => {
    if (postBedStatus === StatusEnum.Failed) {
      notifyError('Unable to create table', apiError, NotificaitonPlacement.bottomLeft)
    } else if (postBedStatus === StatusEnum.Success) {
      notifySuccess('Success', 'Table created successfully')
      onCreate && onCreate()
      dispatch(getBedsAsync({ studioId: id }))
    }
    return () => {
      dispatch(resetPostBedStatus())
    }
  }, [postBedStatus])

  const onFinish = (values: any) => {
    const combinedData = { name: values.name, clinicId: id }
    dispatch(postBedAsync({ data: combinedData }))
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const onCancel = () => {
    form.resetFields()
    onClose && onClose()
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <Form
          labelWrap={true}
          form={form}
          name="basic"
          layout="vertical"
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          autoComplete="off"
        >
          <Form.Item<ICreateBed>
            label="Table Name"
            name="name"
            rules={[{ required: true, message: 'Please input bed name!' }]}
          >
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter Table Name" />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16 }}>
            <div className={styles.footer}>
              <Button className={styles.button} children="Cancel" type="default" onClick={onCancel}></Button>
              <Button className={styles.button} children="Submit" htmlType="submit" type="primary"></Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

interface UpdateBedProps {
  onClose: () => void
  onUpdate?: () => void
  bedId: string
}
export interface IUpdateBed {
  name: string
  code: string
  clinicId: string | undefined
}
export const EditBed = ({ onClose, onUpdate, bedId }: UpdateBedProps) => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const putBedStatus = useAppSelector((state) => state.admin.studio.putBedStatus)
  const apiError = useAppSelector((state) => state.admin.studio.error)
  const beds = useAppSelector((state) => state.admin.studio.beds)
  const [form] = Form.useForm()
  const [value, setValue] = useState('')

  const bed: any = useMemo(() => {
    let temp = beds?.find((B) => B.id === bedId)
    if (!temp) {
      return null
    } else {
      return {
        name: temp.name,
        code: temp.code,
        clinicId: temp.id,
      }
    }
  }, [beds])

  useEffect(() => {
    if (putBedStatus === StatusEnum.Failed) {
      notifyError('Unable to update table', apiError, NotificaitonPlacement.bottomLeft)
    } else if (putBedStatus === StatusEnum.Success) {
      notifySuccess('Success', 'Table updated successfully')
      onUpdate && onUpdate()
      dispatch(getBedsAsync({ studioId: id }))
    }
    return () => {
      dispatch(resetPutBedStatus())
    }
  }, [putBedStatus])

  const onFinish = (values: any) => {
    const combinedData = { name: values.name, clinicId: id }
    dispatch(putBedAsync({ data: combinedData, bedId: bedId }))
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const onCancel = () => {
    form.resetFields()
    onClose && onClose()
  }

  // const copyToClipboard = (value: any) => {
  //   navigator.clipboard.writeText(value)
  //   console.log('clip ran')
  //   // You can add a success message or notification here
  // }

  return (
    <div className={styles.wrapper}>
      <div>
        <Form
          labelWrap={true}
          form={form}
          name="basic"
          layout="vertical"
          // wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={bed}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          autoComplete="off"
        >
          <Form.Item<IUpdateBed>
            label="Table Name"
            name="name"
            rules={[{ required: true, message: 'Please input bed name!' }]}
          >
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter bed name" />
          </Form.Item>
          <Form.Item<IUpdateBed>
            label="Code"
            name="code"
            rules={[{ required: true, message: 'Please input bed name!' }]}
            style={{ display: 'inline-block' }}
          >
            <Input style={{ width: 317 }} disabled={true} />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16 }}>
            <div className={styles.footer}>
              <Button className={styles.button} children="Cancel" type="default" onClick={onCancel}></Button>
              <Button className={styles.button} children="Save" htmlType="submit" type="primary"></Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export interface ICreateSlot {
  day: string
  startTime: string
  startTimeMeridiem?: string
  endTime: string
  endTimeMeridiem?: string
  noOfTables: string | any
  bookingLimitPerTable: string | any
  isActive: boolean
  clinicId: string | undefined
}
export interface Schedule {
  MON: Subslot[]
  TUE: Subslot[]
  WED: Subslot[]
  THU: Subslot[]
  FRI: Subslot[]
  SAT: Subslot[]
  SUN: Subslot[]
}

export interface Subslot {
  id: number
  startTime: {
    label: string
    value: string
  }
  startMeridiem: {
    label: string
    value: string
  }
  endTime: {
    label: string
    value: string
  }
  endMeridiem: {
    label: string
    value: string
  }
  day: string
  bookingLimitPerTable: any
  noOfTables: any
  timezone: string
  isActive: boolean
}
const DEFAULT_SCHEDULE = [
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'mon',
    bookingLimitPerTable: '1',
    noOfTables: '1',
    timezone: 'America/New_York',
    isActive: false,
  },
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'tue',
    bookingLimitPerTable: '1',
    noOfTables: '1',
    timezone: 'America/New_York',
    isActive: false,
  },
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'wed',
    bookingLimitPerTable: '1',
    noOfTables: '1',
    timezone: 'America/New_York',
    isActive: false,
  },
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'thu',
    bookingLimitPerTable: '1',
    noOfTables: '1',
    timezone: 'America/New_York',
    isActive: false,
  },
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'fri',
    bookingLimitPerTable: '1',
    noOfTables: '1',
    timezone: 'America/New_York',
    isActive: false,
  },
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'sat',
    bookingLimitPerTable: '1',
    noOfTables: '1',
    timezone: 'America/New_York',
    isActive: false,
  },
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'sun',
    bookingLimitPerTable: '1',
    noOfTables: '1',
    timezone: 'America/New_York',
    isActive: false,
  },
]
export const SlotTab = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const slots = useAppSelector((state) => state.admin.studio.slots)
  const slotsStatus = useAppSelector((state) => state.admin.studio.slotsStatus)
  const postSlotsStatus = useAppSelector((state) => state.admin.studio.postSlotStatus)
  const apiError = useAppSelector((state) => state.admin.studio.error)
  const [showAvailabilitySave, setShowAvailabilitySave] = useState(false)
  const [schedule, setSchedule] = useState<Array<Subslot>>([])
  const [existingSchedule, setExistingSchedule] = useState<Array<Subslot>>([])
  const [showAll, setShowAll] = useState(false)
  const [isCancel, setIsCancel] = useState(false)

  const userTimezone = useMemo(() => {
    const timezone = getUserTimezone()
    return timezone
  }, [slots])

  useEffect(() => {
    dispatch(getSlotsAsync({ studioId: id }))
    return () => {
      dispatch(resetSlotsStatus())
      dispatch(resetSlots())
    }
  }, [isCancel])

  useEffect(() => {
    if (slotsStatus === StatusEnum.Success) {
      if (slots.length !== 0) {
        const updatedSchedule = assignDayIds(slots)
        setSchedule(updatedSchedule)
        setExistingSchedule(updatedSchedule)
      }
    }
    return () => {}
  }, [slots])

  useEffect(() => {
    if (postSlotsStatus === StatusEnum.Failed) {
      notifyError('Unable to create schedule', apiError)
    } else if (postSlotsStatus === StatusEnum.Success) {
      notifySuccess('Success', 'Schedule updated successfully')
    }
    return () => {
      dispatch(resetPostSlotStatus())
    }
  }, [postSlotsStatus])

  const filteredSchedules = useMemo(() => {
    if (showAll) {
      return schedule
    } else {
      return schedule.filter((item) => item.isActive)
    }
  }, [showAll, schedule])

  const scheduleObj = {
    MON: filteredSchedules.filter((s) => s.day === 'mon'),
    TUE: filteredSchedules.filter((s) => s.day === 'tue'),
    WED: filteredSchedules.filter((s) => s.day === 'wed'),
    THU: filteredSchedules.filter((s) => s.day === 'thu'),
    FRI: filteredSchedules.filter((s) => s.day === 'fri'),
    SAT: filteredSchedules.filter((s) => s.day === 'sat'),
    SUN: filteredSchedules.filter((s) => s.day === 'sun'),
  }

  const onScheduleChange = (time: any, id: any, day: any, isStartTime: boolean): any => {
    const updatedSchedule: any = { ...scheduleObj }
    const daySchedule = [...updatedSchedule[day]]
    const entryIndex = daySchedule.findIndex((entry) => entry.id === id)
    if (entryIndex !== -1 && isStartTime === true) {
      daySchedule[entryIndex] = {
        ...daySchedule[entryIndex],
        startTime: { label: time, value: time },
      }
      updatedSchedule[day] = daySchedule
    } else if (entryIndex !== -1 && isStartTime === false) {
      daySchedule[entryIndex] = {
        ...daySchedule[entryIndex],
        endTime: { label: time, value: time },
      }
      updatedSchedule[day] = daySchedule
    }
    setSchedule(convertScheduleToApi(updatedSchedule))
  }

  const onScheduleMeridiemChange = (meridiem: any, id: any, day: any, isStartMeridiem: boolean): any => {
    const updatedSchedule: any = { ...scheduleObj }
    const daySchedule = [...updatedSchedule[day]]
    const entryIndex = daySchedule.findIndex((entry) => entry.id === id)
    if (entryIndex !== -1 && isStartMeridiem === true) {
      daySchedule[entryIndex] = {
        ...daySchedule[entryIndex],
        startMeridiem: { label: meridiem, value: meridiem },
      }
      updatedSchedule[day] = daySchedule
    } else if (entryIndex !== -1 && isStartMeridiem === false) {
      daySchedule[entryIndex] = {
        ...daySchedule[entryIndex],
        endMeridiem: { label: meridiem, value: meridiem },
      }
      updatedSchedule[day] = daySchedule
    }
    setSchedule(convertScheduleToApi(updatedSchedule))
  }

  function convertScheduleToApi(s: any) {
    let array: Array<Subslot> = []

    s['MON'].map((d: Subslot) => array.push(d))
    s['TUE'].map((d: Subslot) => array.push(d))
    s['WED'].map((d: Subslot) => array.push(d))
    s['THU'].map((d: Subslot) => array.push(d))
    s['FRI'].map((d: Subslot) => array.push(d))
    s['SAT'].map((d: Subslot) => array.push(d))
    s['SUN'].map((d: Subslot) => array.push(d))

    return array
  }

  const handleAddRow = (day: any) => {
    const updatedSchedule: any = { ...scheduleObj }
    const lastId: any = updatedSchedule[day][updatedSchedule[day].length - 1].id
    updatedSchedule[day].push({
      id: lastId + 1,
      startTime: { label: '8:00', value: '8:00' },
      startMeridiem: { label: 'AM', value: 'AM' },
      endTime: { label: '8:00', value: '8:00' },
      endMeridiem: { label: 'PM', value: 'PM' },
      day: day.toLowerCase(),
      bookingLimitPerTable: '1',
      noOfTables: '1',
      timezone: userTimezone,
      isActive: false,
    })
    setSchedule(convertScheduleToApi(updatedSchedule))
  }

  const handleDeleteRow = (day: any, id: any) => {
    if (id !== 1) {
      const updatedSchedule: any = { ...scheduleObj }
      updatedSchedule[day] = updatedSchedule[day].filter((slot: any) => slot.id !== id)
      setSchedule(convertScheduleToApi(updatedSchedule))
    }
  }

  const handleToggleActive = (day: string, id: number) => {
    const updatedSchedule: any = { ...scheduleObj }
    const daySchedule: any = [...updatedSchedule[day]]
    const entryIndex = daySchedule.findIndex((entry: any) => entry.id === id)

    if (entryIndex !== -1) {
      daySchedule[entryIndex] = {
        ...daySchedule[entryIndex],
        isActive: !daySchedule[entryIndex].isActive,
      }

      updatedSchedule[day] = daySchedule
    }

    setSchedule(convertScheduleToApi(updatedSchedule))
  }

  const onBookingLimitPerTableChange = (bookingLimitPerTable: any, id: any, day: any): any => {
    const updatedSchedule: any = { ...scheduleObj }
    const daySchedule = [...updatedSchedule[day]]
    const entryIndex = daySchedule.findIndex((entry) => entry.id === id)

    daySchedule[entryIndex] = {
      ...daySchedule[entryIndex],
      bookingLimitPerTable: bookingLimitPerTable,
    }
    updatedSchedule[day] = daySchedule
    setSchedule(convertScheduleToApi(updatedSchedule))
  }

  const onNoOfTableChange = (noOfTables: any, id: any, day: any): any => {
    const updatedSchedule: any = { ...scheduleObj }
    const daySchedule = [...updatedSchedule[day]]
    const entryIndex = daySchedule.findIndex((entry) => entry.id === id)

    daySchedule[entryIndex] = {
      ...daySchedule[entryIndex],
      noOfTables: noOfTables,
    }
    updatedSchedule[day] = daySchedule
    setSchedule(convertScheduleToApi(updatedSchedule))
  }

  const handleAvailabilitySubmit = () => {
    const availability: any = schedule.map((s) => {
      const parsedStartTime = parse(`${s?.startTime.label} ${s?.startMeridiem.label}`, 'h:mm a', new Date())
      const formattedStartTime = format(parsedStartTime, 'HH:mm:ss')
      const parsedEndTime = parse(`${s?.endTime.label} ${s?.endMeridiem.label}`, 'h:mm a', new Date())
      const formattedEndTime = format(parsedEndTime, 'HH:mm:ss')
      const limitPerTable = parseInt(s.bookingLimitPerTable)
      const noOfTables = parseInt(s.noOfTables)

      return {
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        day: s.day,
        timezone: slots.length !== 0 ? slots[0].timezone : userTimezone,
        isActive: s.isActive,
        bookingLimitPerTable:
          limitPerTable === null || limitPerTable === undefined || limitPerTable === 0 || Number.isNaN(limitPerTable)
            ? 1
            : limitPerTable,
        noOfTables:
          noOfTables === null || noOfTables === undefined || noOfTables === 0 || Number.isNaN(noOfTables)
            ? 1
            : noOfTables,
      }
    })
    const availData: any = {
      slots: [...availability],
    }
    try {
      console.log(availData, 'yyyyyyy')
      dispatch(postSlotAsync({ data: availData, clinicId: id }))
    } catch {}

    setShowAvailabilitySave(false)
    setShowAll(false)
  }

  const handleAvailabilityCancel = () => {
    setIsCancel(!isCancel)
    setSchedule(existingSchedule)
    setShowAll(false)
    setShowAvailabilitySave(false)
  }

  const assignDayIds = (schedule: any) => {
    const idCount: any = {}
    const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    const scheduledDays = schedule.map((item: any) => item.day)
    const missingDays = daysOfWeek.filter((day) => !scheduledDays.includes(day))

    const defaultObject = {
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '20:00:00',
      endMeridiem: 'PM',
      bookingLimitPerTable: '',
      noOfTables: '',
      isActive: false,
    }

    const result = [...schedule]

    for (const day of missingDays) {
      result.push({ ...defaultObject, day, id: 1 })
    }

    return result.map((item: any) => {
      const { day, startTime, endTime, bookingLimitPerTable, noOfTables } = item
      if (!idCount[day]) {
        idCount[day] = 1
      }
      const id = idCount[day]
      idCount[day]++

      if (startTime === '08:00:00' && endTime === '20:00:00') {
        return {
          startTime: {
            label: '8:00',
            value: '8:00',
          },
          startMeridiem: {
            label: 'AM',
            value: 'AM',
          },
          endTime: {
            label: '8:00',
            value: '8:00',
          },
          endMeridiem: {
            label: 'PM',
            value: 'PM',
          },
          id,
          day: item.day,
          bookingLimitPerTable: bookingLimitPerTable,
          noOfTables: noOfTables,
          isActive: item.isActive,
          timezone: item.timezone,
        }
      }

      return {
        startTime: {
          label: format(new Date(`2024-01-01T${startTime}`), 'h:mm'),
          value: format(new Date(`2024-01-01T${startTime}`), 'h:mm'),
        },
        startMeridiem: {
          label: format(new Date(`2024-01-01T${startTime}`), 'a'),
          value: format(new Date(`2024-01-01T${startTime}`), 'a'),
        },
        endTime: {
          label: format(new Date(`2024-01-01T${endTime}`), 'h:mm'),
          value: format(new Date(`2024-01-01T${endTime}`), 'h:mm'),
        },
        endMeridiem: {
          label: format(new Date(`2024-01-01T${endTime}`), 'a'),
          value: format(new Date(`2024-01-01T${endTime}`), 'a'),
        },
        id,
        day: item.day,
        bookingLimitPerTable: bookingLimitPerTable,
        noOfTables: noOfTables,
        isActive: item.isActive,
        timezone: item.timezone,
      }
    })
  }

  console.log(schedule, 'this is the sched')

  return (
    <div>
      {slotsStatus === StatusEnum.Pending && <Loading style={{ width: '100%' }}></Loading>}
      {slotsStatus === StatusEnum.Success && (
        <div>
          <div className={styles.scheduleContainer}>
            {filteredSchedules.length === 0 && (
              <div
                style={{
                  width: '78vw',
                  height: '40vh',
                  background: '#20212E',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  className={styles.button}
                  children="Create Schedule"
                  type="primary"
                  onClick={() => {
                    setSchedule(DEFAULT_SCHEDULE)
                    setShowAll(true)
                    setShowAvailabilitySave(true)
                  }}
                ></Button>
              </div>
            )}
            <div className={styles.editScheduleBtn}>
              {filteredSchedules.length !== 0 && (
                <img
                  style={{ cursor: 'pointer' }}
                  src={editScheduleSvg}
                  onClick={() => {
                    setShowAll(true)
                    setShowAvailabilitySave(true)
                  }}
                  alt="Edit Schedule"
                ></img>
              )}
            </div>

            {/* {filteredSchedules.length > 0 && (
            <div
              style={{
                display: 'flex',
  
                alignItems: 'center',
              }}
            >
              <div style={{ width: '120px' }}>Day</div>
              <div style={{ width: '200px' }}>Start</div>
              <div style={{ width: '200px' }}>End</div>
              <div style={{ width: '150px' }}>Tables</div>
              <div style={{ width: '150px' }}>Frequency</div>
            </div>
          )} */}

            {filteredSchedules.length > 0 &&
              Object.entries(scheduleObj).map(
                ([day, daySchedule], index) =>
                  daySchedule.length > 0 && (
                    <div key={index} className={styles.availabilityRow}>
                      <div>
                        <div style={{ display: index >= 1 ? 'none' : '', marginBottom: '8px', fontWeight: 700 }}>
                          Day
                        </div>
                        <div className={styles.daysWrapper}>
                          <h3 className={styles.daysHeader}>{day}</h3>
                        </div>
                      </div>

                      <div className={styles.availabilityDays}>
                        <div>
                          {Array.isArray(daySchedule) &&
                            daySchedule.map((time, timeIndex) => (
                              <div className={styles.availabilitySet} key={time.id}>
                                <div className={styles.timeDropdown}>
                                  <div className={styles.timeDropdownContainer}>
                                    <div>
                                      <div className={styles.startTimeContainer}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                          <label
                                            style={{
                                              display: timeIndex || index >= 1 ? 'none' : '',
                                              marginBottom: '6px',
                                              marginTop: '-0.3rem',
                                              fontWeight: 700,
                                            }}
                                          >
                                            Start
                                          </label>
                                          <Select
                                            value={time.startTime.label}
                                            defaultValue="8:00"
                                            onChange={(value: any) => {
                                              onScheduleChange(value, time.id, day, true)
                                              setShowAvailabilitySave(true)
                                            }}
                                            placeholder={'Start Time'}
                                            options={AppConstants.TimeSelectorFifteenInterval}
                                            suffixIcon={
                                              <img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />
                                            }
                                            style={{ textTransform: 'capitalize', width: '100px' }}
                                            dropdownStyle={{ textTransform: 'capitalize', border: 'solid 1px #2c2d3c' }}
                                            disabled={showAll ? false : true}
                                          ></Select>
                                        </div>
                                        <Select
                                          value={time.startMeridiem.label}
                                          defaultValue="AM"
                                          onChange={(value: any) => {
                                            onScheduleMeridiemChange(value, time.id, day, true)
                                            setShowAvailabilitySave(true)
                                          }}
                                          placeholder={'AM/PM'}
                                          options={AppConstants.Meridiem}
                                          suffixIcon={
                                            <img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />
                                          }
                                          style={{
                                            textTransform: 'capitalize',
                                            width: '100px',
                                            marginTop: timeIndex || index >= 1 ? '' : '2.05rem',
                                          }}
                                          dropdownStyle={{ textTransform: 'capitalize', border: 'solid 1px #2c2d3c' }}
                                          disabled={showAll ? false : true}
                                        ></Select>
                                      </div>
                                    </div>
                                    <div>
                                      <div className={styles.endTimeContainer}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                          <label
                                            style={{
                                              display: timeIndex || index >= 1 ? 'none' : '',
                                              marginBottom: '6px',
                                              marginTop: '-0.3rem',
                                              fontWeight: 700,
                                            }}
                                          >
                                            End
                                          </label>
                                          <Select
                                            value={time.endTime.label}
                                            defaultValue="8:00"
                                            onChange={(value: any) => {
                                              onScheduleChange(value, time.id, day, false)
                                              setShowAvailabilitySave(true)
                                            }}
                                            placeholder={'End Time'}
                                            options={AppConstants.TimeSelectorFifteenInterval}
                                            suffixIcon={
                                              <img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />
                                            }
                                            style={{
                                              textTransform: 'capitalize',
                                              width: '100px',
                                            }}
                                            dropdownStyle={{ textTransform: 'capitalize', border: 'solid 1px #2c2d3c' }}
                                            disabled={showAll ? false : true}
                                          ></Select>
                                        </div>

                                        <Select
                                          value={time.endMeridiem.label}
                                          defaultValue="PM"
                                          onChange={(value: any) => {
                                            onScheduleMeridiemChange(value, time.id, day, false)
                                            setShowAvailabilitySave(true)
                                          }}
                                          placeholder={'AM/PM'}
                                          options={AppConstants.Meridiem}
                                          suffixIcon={
                                            <img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />
                                          }
                                          style={{
                                            textTransform: 'capitalize',
                                            width: '100px',
                                            marginTop: timeIndex || index >= 1 ? '' : '2.05rem',
                                          }}
                                          dropdownStyle={{ textTransform: 'capitalize', border: 'solid 1px #2c2d3c' }}
                                          disabled={showAll ? false : true}
                                        ></Select>
                                      </div>
                                    </div>
                                    <div className={styles.tableAndFrequency}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label
                                          style={{
                                            display: timeIndex || index >= 1 ? 'none' : '',
                                            marginBottom: '6px',
                                            marginTop: '-0.3rem',
                                            fontWeight: 700,
                                          }}
                                        >
                                          Table
                                        </label>
                                        <Input
                                          value={time.noOfTables}
                                          style={{ width: 150, height: '38px', textTransform: 'capitalize' }}
                                          placeholder="No.of tables"
                                          disabled={showAll ? false : true}
                                          onChange={(e) => {
                                            onNoOfTableChange(e.target.value, time.id, day)
                                          }}
                                        />
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label
                                          style={{
                                            display: timeIndex || index >= 1 ? 'none' : '',
                                            marginBottom: '6px',
                                            marginTop: '-0.3rem',
                                            fontWeight: 700,
                                          }}
                                        >
                                          Frequency
                                        </label>
                                        <Input
                                          value={time.bookingLimitPerTable}
                                          style={{ width: 150, height: '38px', textTransform: 'capitalize' }}
                                          placeholder="Enter frequency"
                                          disabled={showAll ? false : true}
                                          onChange={(e) => {
                                            onBookingLimitPerTableChange(e.target.value, time.id, day)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {showAll && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {timeIndex > 0 ? (
                                        <div style={{ display: 'flex' }}>
                                          <div style={{ cursor: 'pointer', width: '20px' }}>
                                            <img
                                              style={{ cursor: 'pointer' }}
                                              src={deleteSvg}
                                              alt={'delete'}
                                              onClick={() => {
                                                handleDeleteRow(day, time.id)
                                                setShowAvailabilitySave(true)
                                              }}
                                            ></img>
                                          </div>
                                          <div style={{ marginLeft: '8px' }}>
                                            <Switch
                                              onClick={() => {
                                                handleToggleActive(day, time.id)
                                                setShowAvailabilitySave(true)
                                              }}
                                              checked={time.isActive}
                                            ></Switch>
                                          </div>
                                        </div>
                                      ) : (
                                        <div style={{ display: 'flex' }}>
                                          <div
                                            style={{
                                              cursor: 'pointer',
                                              width: '20px',
                                              marginTop: timeIndex || index >= 1 ? '' : '2.05rem',
                                            }}
                                          >
                                            <img
                                              className={styles.addBtnParent}
                                              src={addSvg}
                                              alt={'add'}
                                              onClick={() => {
                                                handleAddRow(day)
                                                setShowAvailabilitySave(true)
                                              }}
                                            ></img>
                                          </div>
                                          <div
                                            style={{
                                              marginLeft: '8px',
                                              marginTop: timeIndex || index >= 1 ? '' : '2.05rem',
                                            }}
                                            className={styles.radioToggleButtonParent}
                                          >
                                            <Switch
                                              onClick={() => {
                                                handleToggleActive(day, time.id)
                                                setShowAvailabilitySave(true)
                                              }}
                                              checked={time.isActive}
                                            ></Switch>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ),
              )}
          </div>
          <div className={styles.availabilityBtnContainer}>
            {showAvailabilitySave && (
              <Form.Item>
                <div className={styles.footer}>
                  <Button
                    className={styles.button}
                    children="Cancel"
                    type="default"
                    onClick={handleAvailabilityCancel}
                  ></Button>
                  <Button
                    className={styles.button}
                    children="Save"
                    onClick={handleAvailabilitySubmit}
                    type="primary"
                  ></Button>
                </div>
              </Form.Item>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
